const transformRequestOptions = (params) => {
  let options = "";
  for (const key in params) {
    if (typeof params[key] !== "object" && params[key] !== null && params[key] !== undefined) {
      options += `${key}=${params[key]}&`;
    } else if (typeof params[key] === "object" && params[key] && params[key].length) {
      params[key].forEach((el) => {
        options += `${key}=${el}&`;
      });
    }
  }
  return options ? options.slice(0, -1) : options;
};

export default class Base {
  constructor (axios, config = {}) {
    this.axios = axios;
    this.config = config;
  }

  authUser (user) {
    if (!user) {
      return;
    }
    this.config = {
      ...this.config,
      Authorization: `Bearer ${user.token}`,
    };
  }

  unAuthUser () {
    delete this.config.Authorization;
  }

  async makeGetRequest (url, params) {
    const response = await this.axios.get(url, { params, headers: this.config, paramsSerializer: params => transformRequestOptions(params) });
    return response.data;
  }

  async makePostRequest (url, params) {
    const response = await this.axios.post(url, params, { headers: this.config });
    return response.data;
  }

  async makePutRequest (url, params) {
    const response = await this.axios.put(url, params, { headers: this.config });
    return response.data;
  }

  async makePatchRequest (url, params) {
    const response = await this.axios.patch(url, params, { headers: this.config });
    return response.data;
  }

  async makeDeleteRequest (url, params) {
    const response = await this.axios.delete(url, { headers: this.config, data: params });
    return response.data;
  }
}
