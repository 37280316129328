import Base from "./base";

export default class Search extends Base {
  async universalSearch (query, sources) {
    return {
      query,
      items: await this.makeGetRequest("/search", { query, sources }),
    };
  }
}
