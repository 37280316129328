import validator from "validator";

export default {
  methods: {
    validatePosters (item) {
      switch (item.item_type) {
        case "movie":
          return {
            poster: this.generateTMDBImage(item.item.poster_id, "w92"),
          };
        case "tv":
          return {
            poster: this.generateTMDBImage(item.item.poster_id, "w92"),
          };
        case "season":
          return {
            poster: this.generateTMDBImage(item.item.poster_id, "w92"),
          };
        case "episode":
          return {
            poster: this.generateTMDBImage(item.item.still_id, "w185"),
          };
        case "game":
          return {
            poster: this.generateIGDBImage(item.item.cover_id, "cover_small"),
          };
        case "book":
          return {
            poster: this.generateOpenLibraryImage(item.item.cover_id, "ID", "M"),
          };
        case "person":
          return {
            poster: this.generateTMDBImage(item.item.profile_id, "w92"),
          };
        case "author":
          return {
            poster: this.generateOpenLibraryImage(item.item.photo_id, "ID", "M"),
          };
        case "news":
          return {
            poster: item.item.image,
          };
        default:
          return null;
      }
    },
    generateIGDBImage (imageId, size = "cover_big") {
      /**
       * Image url structure:
       *
       * https://images.igdb.com/igdb/image/upload/t_screenshot_med_2x/dfgkfivjrhcksyymh9vw.jpg
       *
       * Break down:
       *
       * https://images.igdb.com/igdb/image/upload/t_{size}/{hash}.jpg
       *
       * size is one of the interchangeable size types listed below.
       * hash is the id of the image. The image sizes are all maximum size but
       * by appending _2x to any size, you can get retina (DPR 2.0) sizes (cover_small_2x).
       *
       * Sizes:
       *
       * cover_small     |  90 x 128  Fit
       * cover_med       | 130 x 185  Fit
       * cover_big       | 264 x 374  Fit
       *
       * logo_med        | 284 x 160  Fit
       *
       * screenshot_med  | 569 x 320  Lfill, Center gravity
       * screenshot_big  | 889 x 500  Lfill, Center gravity
       * screenshot_huge | 1280 x 720  Lfill, Center gravity
       *
       * thumb           | 90 x 90  Thumb, Center gravity
       * micro           | 35 x 35  Thumb, Center gravity
       *
       * 720p            | 1280 x 720  Fit, Center gravity
       * 1080p           | 1920 x 1080  Fit, Center gravity
       *
       * @param {string} imageId - The id of the image
       * @param {string} size - The size of the image
       *
       * @return {string} - The url of the image
       */
      if (imageId === null || imageId === undefined || imageId === "") { return ""; }

      imageId = String(imageId);
      if (validator.isUUID(imageId)) {
        switch (size) {
          case "cover_small":
            size = "coversmall";
            break;
          case "cover_med":
            size = "covermed";
            break;
          case "cover_big":
            size = "coverbig";
            break;
        }
        return `https://images.flick.club/${imageId}/${size}`;
      } else {
        return `https://images.igdb.com/igdb/image/upload/t_${size}/${imageId}.jpg`;
      }
    },
    generateOpenLibraryImage (value, key = "ID", size = "L") {
      /**
       * Book covers can be accessed using Cover ID (internal cover ID),
       * OLID (Open Library ID), ISBN, OCLC, LCCN and other identifiers like librarything and goodreads.
       *
       * The covers are available in 3 sizes:
       *
       * S: Small, suitable for use as a thumbnail on a results page on Open Library,
       * M: Medium, suitable for display on a details page on Open Library and,
       * L: Large
       *
       * The URL pattern to access book covers is:
       *
       * https://covers.openlibrary.org/b/$key/$value-$size.jpg
       *
       * Where:
       *
       * key can be any one of ISBN, OCLC, LCCN, OLID and ID (case-insensitive)
       * value is the value of the chosen key
       * size can be one of S, M and L for small, medium and large respectively.
       *
       * @param {string} value - The value of the chosen key
       * @param {string} key - The key can be any one of ISBN, OCLC, LCCN, OLID and ID
       * @param {string} size - The size can be one of S, M and L for small, medium and large respectively
       *
       * @return {string} - The url of the book cover
       */
      if (value === null || value === undefined || value === "") { return ""; }

      value = String(value);
      if (validator.isUUID(value)) {
        switch (size) {
          case "S":
            size = "coversmall";
            break;
          case "M":
            size = "covermed";
            break;
          case "L":
            size = "coverbig";
            break;
        }
        return `https://images.flick.club/${value}/${size}`;
      } else {
        return `https://covers.openlibrary.org/a/${key}/${value}-${size}.jpg?default=false`;
      }
    },
    generateTMDBImage (imagePath, size = "w342") {
      /**
       * Get the URL of an image
       *
       * Available image sizes for posters:
       * "w92", "w154", "w185", "w342", "w500", "w780", or "original"
       *
       * Available image sizes for backdrops:
       * "w300", "w780", "w1280", "original"
       *
       * Available image sizes for stills:
       * "w92", "w185", "w300", "original"
       *
       * Available image sizes for logos:
       * "w45", "w92", "w154", "w185", "w300", "w500", "original"
       *
       * Available image sizes for profiles:
       * "w45", "w185", "h632", "original"
       *
       * @param {string} imagePath - The image ID
       * @param {string} size - The image size. Defaults to "w200".
       *
       * @return {string} - The image URL
       */
      if (imagePath === null || imagePath === undefined || imagePath === "") { return ""; }

      imagePath = String(imagePath);
      if (validator.isUUID(imagePath)) {
        switch (size) {
          case "w45":
            size = "micro";
            break;
          case "w92":
            size = "thumb";
            break;
          case "w154":
            size = "coversmall";
            break;
          case "w185":
            size = "covermed";
            break;
          case "w300":
            size = "coverbig";
            break;
          case "w342":
            size = "coverbig";
            break;
          case "w500":
            size = "720p";
            break;
          case "w632":
            size = "720p";
            break;
          case "w780":
            size = "720p";
            break;
          case "w1280":
            size = "1080p";
            break;
          case "original":
            size = "1080p";
            break;
        }
        return `https://images.flick.club/${imagePath}/${size}`;
      } else {
        return `https://image.tmdb.org/t/p/${size}/${imagePath}`;
      }
    },
    generateFlickImage (imagePath, size = "coverbig") {
      /**
       * Image url structure:
       *
       * https://images.flick.club/dfgkfivjrhcksyymh9vw/720p
       *
       * Break down:
       *
       * https://images.flick.club/{imagePath}/{size}
       *
       * Sizes:
       *
       * coversmall     |  90 x 128  Fit
       * covermed       | 130 x 185  Fit
       * coverbig       | 264 x 374  Fit
       *
       * thumb           | 90 x 90  Thumb, Center gravity
       * micro           | 35 x 35  Thumb, Center gravity
       *
       * 720p            | 1280 x 720  Fit
       * 1080p           | 1920 x 1080  Fit
       *
       * @param {string} imageId - The id of the image
       * @param {string} size - The size of the image
       *
       * @return {string} - The url of the image
       */
      return imagePath ? `https://image.flick.club/${imagePath}/${size}` : "";
    },
  },
};
