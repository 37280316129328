export default {
  methods: {
    getTimeAgo (dateString) {
      const date = Date.parse(dateString);

      const utc_date = new Date().toLocaleString("en-US", { timeZone: "UTC" });
      const now = Date.parse(utc_date);

      const diff = now - date;

      const minutes = Math.floor(diff / 60000); // 1 minute = 60000 milliseconds

      if (minutes < 1) {
        return "just now";
      } else if (minutes === 1) {
        return "1 minute ago";
      } else if (minutes < 60) {
        return `${minutes} minutes ago`;
      } else if (minutes < 120) {
        return "1 hour ago";
      } else if (minutes < 1440) {
        return `${Math.floor(minutes / 60)} hours ago`;
      } else if (minutes < 2880) {
        return "1 day ago";
      } else if (minutes < 43200) {
        return `${Math.floor(minutes / 1440)} days ago`;
      } else if (minutes < 86400) {
        return "1 month ago";
      } else if (minutes < 525600) {
        return `${Math.floor(minutes / 43200)} months ago`;
      } else if (minutes < 1051200) {
        return "1 year ago";
      } else {
        return `${Math.floor(minutes / 525600)} years ago`;
      }
    },
    timeConvert (minutes) {
      const hours = (minutes / 60);
      const rhours = Math.floor(hours);
      const rminutes = Math.round((hours - rhours) * 60);
      if (rminutes < 10) {
        return rhours + ":0" + rminutes;
      } else {
        return rhours + ":" + rminutes;
      }
    },
    unixTimestampConverter (unixTimestamp) {
      if (!unixTimestamp) { return ""; }
      const formatter = new Intl.DateTimeFormat("en", { month: "short" });
      const date = new Date(unixTimestamp * 1000);
      const day = date.getDate();
      const month = formatter.format(date);
      const year = date.getFullYear();
      return `${day} ${month} ${year}`;
    },
    dateConvert (dateString) {
      try {
        const formatter = new Intl.DateTimeFormat("en", { month: "short" });
        const date = new Date(dateString);
        const day = date.getDate();
        const month = formatter.format(date);
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      } catch (e) {
        return dateString;
      }
    },
    getYearFromString (dateString) {
      return new Date(dateString).getFullYear();
    },
    getYearFromUnix (unixTimestamp) {
      if (unixTimestamp) {
        return new Date(unixTimestamp * 1000).getFullYear();
      }
    },
    dateStringToDate (dateString) {
      try {
        return new Date(dateString);
      } catch (e) {
        return dateString;
      }
    },
  },
};
