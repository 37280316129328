<template>
  <v-combobox
    v-model="inputText"
    class="full-width"
    filled
    rounded
    :loading="loading"
    dense
    clearable
    label="Search"
    :items="results"
    :search-input.sync="search"
    hide-details
    append-outer-icon="mdi-magnify"
    append-icon=""
    single-line
    :item-text="item => item._id"
    return-object
    @update:search-input="querySelections"
  >
    <template #item="data">
      <template v-if="data.item.name === 'barForToggles'">
        <v-btn v-if="showLeftArrow" small icon @click.stop.prevent="scrollLeft">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <div class="buttons-line">
          <v-btn small class="mr-2" :outlined="enableMovieResults" @click.stop.prevent="toggleResults('movie')">
            <v-icon class="mr-2" small>
              mdi-movie
            </v-icon>
            Movies
          </v-btn>
          <v-btn small class="mr-2" :outlined="enableTVResults" @click.stop.prevent="toggleResults('tv_show')">
            <v-icon class="mr-2" small>
              mdi-television-classic
            </v-icon>
            TV Shows
          </v-btn>
          <v-btn small class="mr-2" :outlined="enableBookResults" @click.stop.prevent="toggleResults('book')">
            <v-icon class="mr-2" small>
              mdi-book
            </v-icon>
            Books
          </v-btn>
          <v-btn small class="mr-2" :outlined="enableGameResults" @click.stop.prevent="toggleResults('game')">
            <v-icon class="mr-2" small>
              mdi-controller
            </v-icon>
            Games
          </v-btn>
          <v-btn small class="mr-2" :outlined="enablePersonResults" @click.stop.prevent="toggleResults('person')">
            <v-icon class="mr-2" small>
              mdi-account-star
            </v-icon>
            Celebrities
          </v-btn>
          <v-btn small class="mr-2" :outlined="enableAuthorResults" @click.stop.prevent="toggleResults('author')">
            <v-icon class="mr-2" small>
              mdi-book-account
            </v-icon>
            Authors
          </v-btn>
        </div>
        <v-btn v-if="showRightArrow" small icon @click.stop.prevent="scrollRight">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </template>
      <template v-else-if="data.item.source_name === `openlibrary`">
        <NuxtLink :to="`/book/${data.item.slug}`" style="text-decoration:none; width:100%">
          <v-list-item style="max-width: 500px">
            <v-list-item-avatar tile height="90" width="60">
              <Picture
                class="rounded-lg"
                :url="generateOpenLibraryImage(data.item.cover_id, 'ID', 'S')"
                icon="mdi-television-classic"
                :show-icon="false"
                :alt-string="data.item.title"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <v-icon small style="vertical-align: text-bottom;">
                  mdi-book
                </v-icon>
                {{ data.item.title }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <div v-if="data.item.first_publish_year">
                  {{ new Date(data.item.first_publish_year).getFullYear() }}
                </div>
                <div v-if="isNotEmpty(data.item.subjects)">
                  <v-chip v-for="(subject, index) in data.item.subjects.slice(0, 2)" :key="index" class="mr-1" label small>
                    {{ subject }}
                  </v-chip>
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </NuxtLink>
      </template>
      <template v-else-if="data.item.source_name === `igdb`">
        <NuxtLink :to="`/game/${data.item.slug}`" style="text-decoration:none; width:100%">
          <v-list-item style="max-width: 500px">
            <v-list-item-avatar tile height="90" width="60">
              <Picture
                class="rounded-lg"
                :url="generateIGDBImage(data.item.cover_id, 'cover_small')"
                icon="mdi-television-classic"
                :show-icon="false"
                :alt-string="data.item.title"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <v-icon small style="vertical-align: text-bottom;">
                  mdi-controller
                </v-icon>
                {{ data.item.title }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <div v-if="data.item.first_release_date">
                  {{ new Date(Number(data.item.first_release_date.toString() + '000')).getFullYear() }}
                </div>
                <v-chip v-for="(genre, index) in data.item.genres" :key="index" class="mr-1" small>
                  {{ genre }}
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </NuxtLink>
      </template>
      <template v-else-if="data.item.source_name === `tmdb_movie`">
        <NuxtLink :to="`/movie/${data.item.slug}`" style="text-decoration:none; width:100%">
          <v-list-item style="max-width: 500px">
            <v-list-item-avatar tile height="90" width="60">
              <Picture
                class="rounded-lg"
                :url="generateTMDBImage(data.item.poster_id, 'w92')"
                icon="mdi-television-classic"
                :show-icon="false"
                :alt-string="data.item.title"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <v-icon small style="vertical-align: text-bottom;">
                  mdi-movie
                </v-icon>
                {{ data.item.title }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <div v-if="data.item.release_date">
                  {{ new Date(data.item.release_date).getFullYear() }}
                </div>
                <v-chip v-for="(genre, index) in data.item.genres" :key="index" class="mr-1" small>
                  {{ genre }}
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </NuxtLink>
      </template>
      <template v-else-if="data.item.source_name === `tmdb_tv`">
        <NuxtLink :to="`/tv/${data.item.slug}`" style="text-decoration:none; width:100%">
          <v-list-item style="max-width: 500px">
            <v-list-item-avatar tile height="90" width="60">
              <Picture
                class="rounded-lg"
                :url="generateTMDBImage(data.item.poster_id, 'w92')"
                icon="mdi-television-classic"
                :show-icon="false"
                :alt-string="data.item.title"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <v-icon small style="vertical-align: text-bottom;">
                  mdi-television-classic
                </v-icon>
                {{ data.item.title }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <div v-if="data.item.first_air_date">
                  {{ new Date(data.item.first_air_date).getFullYear() }}
                </div>
                <v-chip v-for="(genre, index) in data.item.genres" :key="index" class="mr-1" small>
                  {{ genre }}
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </NuxtLink>
      </template>
      <template v-else-if="data.item.source_name === `author`">
        <NuxtLink :to="`/author/${data.item.slug}`" style="text-decoration:none; width:100%">
          <v-list-item style="max-width: 500px">
            <v-list-item-avatar tile height="90" width="60">
              <Picture
                class="rounded-lg"
                :url="generateOpenLibraryImage(data.item.photo_id, 'ID', 'S')"
                icon="mdi-television-classic"
                :show-icon="false"
                :alt-string="data.item.name"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <v-icon small style="vertical-align: text-bottom;">
                  mdi-book-account
                </v-icon>
                {{ data.item.name }}
              </v-list-item-title>
<!--              <v-list-item-subtitle>-->
<!--                <div v-if="data.item.birthday">-->
<!--                  {{ dateConvert(data.item.birthday) }}-->
<!--                </div>-->
<!--              </v-list-item-subtitle>-->
            </v-list-item-content>
          </v-list-item>
        </NuxtLink>
      </template>
      <template v-else-if="data.item.source_name === `person`">
        <NuxtLink :to="`/person/${data.item.slug}`" style="text-decoration:none; width:100%">
          <v-list-item style="max-width: 500px">
            <v-list-item-avatar tile height="90" width="60">
              <Picture
                class="rounded-lg"
                :url="generateTMDBImage(data.item.profile_id, 'w92')"
                icon="mdi-television-classic"
                :show-icon="false"
                :alt-string="data.item.name"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <v-icon small style="vertical-align: text-bottom;">
                  mdi-account-star
                </v-icon>
                {{ data.item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <div v-if="data.item.birthday">
                  {{ dateConvert(data.item.birthday) }}
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </NuxtLink>
      </template>
    </template>
  </v-combobox>
</template>

<script>
import _ from "lodash";
import Search from "@/services/search";
import image from "@/mixins/image";
import data from "@/mixins/data";
import time from "@/mixins/time";

export default {
  name: "Search",
  components: {
    Picture: () => import("@/components/blocks/Picture.vue"),
  },
  mixins: [image, data, time],
  data () {
    return {
      inputText: "",
      search: "",
      results: [{ name: "barForToggles", disabled: true }],
      enableMovieResults: false,
      enableTVResults: false,
      enableGameResults: false,
      enableBookResults: false,
      enableAuthorResults: false,
      enablePersonResults: false,
      //
      loading: false,
      //
      showLeftArrow: true,
      showRightArrow: true,
      //
      requests: [],
    };
  },
  methods: {
    querySelections: _.debounce(function (value) {
      if (value !== this.search || !value) {
        return;
      }

      const sources = [];
      if (this.enableMovieResults) {
        sources.push("movie");
      }
      if (this.enableTVResults) {
        sources.push("tv");
      }
      if (this.enableGameResults) {
        sources.push("game");
      }
      if (this.enableBookResults) {
        sources.push("book");
      }
      if (this.enablePersonResults) {
        sources.push("person");
      }
      if (this.enableAuthorResults) {
        sources.push("author");
      }

      this.loading = true;
      this.requests.push(this.search);
      const searchAPI = new Search(this.$axios);
      searchAPI.universalSearch(this.search, sources).then((result) => {
        const lastSearchInput = this.requests[this.requests.length - 1];
        if (lastSearchInput === result.query) {
          this.results = [{ name: "barForToggles", disabled: true }, ...result.items];
          this.requests = [];
          this.loading = false;
        }
      });
    }, 200),
    toggleResults (source_name) {
      if (source_name === "movie") {
        this.enableMovieResults = !this.enableMovieResults;
      } else if (source_name === "tv_show") {
        this.enableTVResults = !this.enableTVResults;
      } else if (source_name === "game") {
        this.enableGameResults = !this.enableGameResults;
      } else if (source_name === "book") {
        this.enableBookResults = !this.enableBookResults;
      } else if (source_name === "person") {
        this.enablePersonResults = !this.enablePersonResults;
      } else if (source_name === "author") {
        this.enableAuthorResults = !this.enableAuthorResults;
      }

      if (!this.search) { return; }

      const sources = [];
      if (this.enableMovieResults) {
        sources.push("movie");
      }
      if (this.enableTVResults) {
        sources.push("tv");
      }
      if (this.enableGameResults) {
        sources.push("game");
      }
      if (this.enableBookResults) {
        sources.push("book");
      }
      if (this.enablePersonResults) {
        sources.push("person");
      }
      if (this.enableAuthorResults) {
        sources.push("author");
      }

      this.loading = true;
      this.requests.push(this.search);
      const searchAPI = new Search(this.$axios);
      searchAPI.universalSearch(this.search, sources).then((result) => {
        const lastSearchInput = this.requests[this.requests.length - 1];
        if (lastSearchInput === result.query) {
          this.results = [{ name: "barForToggles", disabled: true }, ...result.items];
          this.requests = [];
          this.loading = false;
        }
      });
    },
    scrollRight () {
      const elmnt = document.getElementsByClassName("buttons-line")[0];
      elmnt.scrollLeft += 200;
    },
    scrollLeft () {
      const elmnt = document.getElementsByClassName("buttons-line")[0];
      elmnt.scrollLeft -= 200;
    },
  },
};
</script>

<style scoped>
/* Hide scrollbar for IE, Edge and Firefox */
.buttons-line {
  width: 200px;
  overflow-x: scroll;
  flex: inherit;
  white-space: nowrap;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.buttons-line::-webkit-scrollbar {
  display: none;
}
</style>
