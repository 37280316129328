export default {
  methods: {
    isNotEmpty (list) {
      return list && list.length > 0;
    },
    empty (list) {
      switch (typeof list) {
        case "string":
          return list === "";
        case "number":
          return list === 0;
        case "array":
          return list && list.length === 0;
        case "object":
          return list === null || list === undefined || Object.keys(list).length === 0;
      }
    },
    numberFixed (num, fixed = 1) {
      if (!num) { return 0; }
      return Number(num).toFixed(fixed);
    },
    formatNumber (num) {
      if (num >= 1e9) {
        return Math.ceil(num / 1e9) + "B";
      } else if (num >= 1e6) {
        return Math.ceil(num / 1e6) + "M";
      } else if (num >= 1e3) {
        return Math.ceil(num / 1e3) + "K";
      } else {
        return num;
      }
    },
    convertNumberToWords (num) {
      const unitsMap = ["zero", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"];
      const tensMap = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];
      if (num < 20) { return unitsMap[num]; }
      if (num < 100) { return tensMap[Math.floor(num / 10)] + (num % 10 !== 0 ? "-" + unitsMap[num % 10] : ""); }
      if (num < 1000) { return unitsMap[Math.floor(num / 100)] + " hundred" + (num % 100 !== 0 ? " and " + this.convertNumberToWords(num % 100) : ""); }
      return this.convertNumberToWords(Math.floor(num / 1000)) + " thousand" + (num % 1000 !== 0 ? " " + this.convertNumberToWords(num % 1000) : "");
    },
  },
};
